import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "371264ad-5a5c-4632-b907-65f812869fcd",
        authority: "https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906",
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage", 
        storeAuthStateInCookie: false, 
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level : any, message : any, containsPii : any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        return;
                    case LogLevel.Warning:
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com"
};
