import { LogLevel } from "@azure/msal-browser";

const b2cTenantName = "xomapps";
const b2cSignInPolicy = "B2C_1A_SIGNIN";

export const b2cPolicies = {
    names: {
        signUpSignIn: b2cSignInPolicy
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${b2cTenantName}.b2clogin.com/${b2cTenantName}.onmicrosoft.com/${b2cSignInPolicy}`,
        }
    },
    authorityDomain: `${b2cTenantName}.b2clogin.com`
};

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_TFP_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level , message , containsPii ) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        return;
                    case LogLevel.Warning:
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ['openid', 'profile', 'email'],
    domainHint: 'ExxonMobilPingFedSAML'
};

export const loginEasRequest = {
    scopes: ['openid', 'profile', 'email'],
    domainHint: 'ExxonMobilPingFedSAML',
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const getCurrentB2cDomainHint = () => {
    return 'yes' === localStorage.getItem('thirdPartyLogon')
      ? loginPingRequest.domainHint
      : loginRequest.domainHint;
  };
