import { useState, useEffect } from "react";
import { loginRequest } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { userForm, userTMS6Form } from "../../forms/userForm";
import Form from "../../blocks/form";
import Loader from "../../elements/loader";
import { toast, ToastContainer } from "react-toastify";
import { apiClient } from "../../lib/axios";

function AdminUserUpdate(props) {
  var [stateOnLoading, setLoading] = useState(true);
  var [stateExternalID, setExternalID] = useState();
  var [stateInternalID, setInternalID] = useState();
  var [stateExternalInternalID, setExternalInternalID] = useState();
  var [stateFirstName, setFirstName] = useState();
  var [stateLastName, setLastName] = useState();
  var [stateCompanyName, setCompanyName] = useState();
  var [statePhoneNumber, setPhoneNumber] = useState();
  var [statePhoneCountryCode, setPhoneCountryCode] = useState();
  var [stateEmail, setEmail] = useState();
  var [stateRole, setRole] = useState();

  function setFirstNameValues(evt) {
    setFirstName(evt.target.value);
  }

  function setLastNameValues(evt) {
    setLastName(evt.target.value);
  }

  function setCompanyNameValues(evt) {
    setCompanyName(evt.target.value);
  }

  function setPhoneNumberValues(evt) {
    setPhoneNumber(evt.target.value);
  }

  function setPhoneCountryCodeValues(evt) {
    setPhoneCountryCode(evt.target.value);
  }

  function setEmailValues(evt) {
    setEmail(evt.target.value);
  }

  function setRoleValues(evt) {
    setRole(evt.label.toString());
  }

  function submitForm() {
    toast("Updating user...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

      var form = {
        id: props.userId,
        firstName: stateFirstName,
        lastName: stateLastName,
        email: stateEmail,
        role: stateRole,
        internalId: stateInternalID,
        externalId: stateExternalID,
      };

      apiClient
        .put("user/update", form)
        .then(
          (response) => {
            toast.success("Success to update the user.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() =>{
              window.location.reload();
            }, 2500);
          },
          (error) => {
            toast.error("Error to edit the user. Try again later.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        );
  }

  function setInternalIdValues(evt) {
    setInternalID(evt.target.value);
  }

  function setExternalIdValues(evt) {
    setExternalID(evt.target.value);
  }

  const getUserInformation = () => {
    try{
        apiClient
          .get(`user?userId=${props.userId}`, {
            headers: {
              "Access-Control-Allow-Origin": "*"
            },
          })
          .then((response) => {
            setLoading(false);
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setEmail(response.data.email);
            setRole(response.data.profile);
            setExternalID(response.data.externalCode);
            setInternalID(response.data.internalCode);
          });
        return true;
      }
      catch(e)  {
        return false;
      };
  };

  useEffect(() => {
    getUserInformation();
  }, []);

  return (
    <>
      <>
        {stateOnLoading ? (
          <div className="loader-area-limiter">
            <Loader />
          </div>
        ) : (
          <div className="container container-space">
            <div className="row alignment">
              <Form
                fields={userTMS6Form(
                  stateInternalID,
                  setInternalIdValues,
                  stateExternalID,
                  setExternalIdValues
                )}
              />
              <Form
                fields={userForm(
                  stateFirstName,
                  setFirstNameValues,
                  stateLastName,
                  setLastNameValues,
                  stateEmail,
                  setEmailValues,
                  stateRole,
                  setRoleValues
                )}
              />
              <button
                onClick={submitForm}
                className="em-c-btn em-c-btn--primary btn--right em-u-margin-top-double create-user-margin"
                id="index-button"
              >
                <span className="em-c-btn__text ">Update</span>
              </button>
            </div>
            <ToastContainer />
          </div>
        )}
      </>
    </>
  );
}

export default AdminUserUpdate;
