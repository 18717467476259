import { Outlet } from "react-router-dom";
import Header from "../blocks/header";
import Footer from "../blocks/footer";

import { apiClient } from "../lib/axios";
import { useState, useEffect } from "react";
import { authInstance } from "../utils/auth";

function Layout() {
  var [stateIsAdmin, setIsAdmin] = useState(false);

  authInstance()
  
  async function fetchIsAdmin(){
    try {
      const getAdminInfo = await apiClient.get('me/IsAdmin').then((response) => {
        setIsAdmin(response.data)
      });
      
    } catch (error) {
      throw new Error('Authentication failed')
    }
  }

  useEffect(() => {
    fetchIsAdmin()
  }, [])

  return (
    <>
      <Header isAdmin={stateIsAdmin}/>
      <div id="container">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default Layout;
