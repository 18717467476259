const PageHeader = ({logged}) => {
    return (
      logged ?
      <>
      <div className="em-c-hero  " >
      <div className="em-c-hero__body hero-title">
      </div>
    </div>
    </>:
    <>
    <div className="em-c-hero  " >
      <div className="em-c-hero__body">
        <p className="em-c-hero__desc" id="blockDesciption">
          To find your products easily.
        </p>
      </div>
    </div>
  </>
    )
  };
  
  export default PageHeader;