import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './layout/css/em-standard-2.0.2.min.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import AuthProvider from './providers/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AuthProvider>
);