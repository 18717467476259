import { NavLink } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { ImWarning } from "react-icons/im";

import './styles.css';

export function CardNew({ name, address, disruptionType, terminal_id, disruptionStartDateTime, disruptionEndDateTime }) {
    const disruptionVariant = (type) => {
        switch (type) {
            case 'Terminal Operating':
                return {
                    icon: <FaCheckCircle size={20} />,
                    borderColor: 'rgb(58, 156, 58)'
                };
            case 'Terminal Operating with planned disruption':
                return {
                    icon: <FaCheckCircle size={20} />,
                    borderColor: 'rgb(179, 144, 2)'
                };
            case 'Downtime':
                return {
                    icon: <ImWarning size={20} />,
                    borderColor: 'red'
                };
            default:
                return {
                    icon: <FaCheckCircle size={20} />,
                    borderColor: 'rgb(58, 156, 58)'
                };
        }
    };

    const { icon, borderColor } = disruptionVariant(disruptionType);
    const restrictedTermIds = ["0000145", "0000242", "0000144", "0000231", "0000274"];
    return (
        <div className="container-card" style={{ borderTop: `20px solid ${borderColor}` }}>
            <div className='content-card'>
                <div className='terminal-info-content'>
                    <span>{name}</span>
                    <p>{address}</p>
                </div>
                <div className='terminal-status-content'>
                    <div className='terminal-status-info-container'>
                        <span>realtime status</span>
                        <div className='terminal-status-info'>
                            {icon}
                            <span>{disruptionType}</span>
                        </div> 
                    </div>
                    {disruptionType === 'Downtime' &&
                        <div className="terminal-status-disruption">
                            <div className="disruption-info">
                                <span>From:</span>
                                {disruptionStartDateTime}
                            </div>
                            <div className="disruption-info">
                                <span>To:</span>
                                {disruptionEndDateTime}
                            </div>
                        </div>  
                    }
                </div>
            </div>
            <div className="actions-container">
                {!restrictedTermIds.includes(terminal_id) && (
                    <>
                        <NavLink to={`/terminal/${terminal_id}`}>
                            <button className="main-btn">Terminal status</button>
                        </NavLink>
                        <NavLink to={`/preLoad/${terminal_id}`}>
                            <button className="secondary-btn">PreLoad</button>
                        </NavLink>                    
                    </>
                )}

                <NavLink to={`/ebol/${terminal_id}`}>
                  <button className="secondary-btn">eBOL</button>
                </NavLink>
            </div>
        </div>
    );
}
