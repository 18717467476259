import axios from "axios"

export default class API {

    constructor(){
        this.api = axios.create({
            baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
            headers: {
                "Internal_id" : document.cookie.split('; ').find((row) => row.startsWith('internalAccount='))?.split('=')[1]
            }
        });
    }
}