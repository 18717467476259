import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./views/Index";
import Layout from "./views/Layout";
import Loggin from "./views/Loggin";
import TerminalInformation from "./views/TerminalInformation";
import Bol from "./views/eBol";
import PreLoad from "./views/PreLoad";
import AdminIndex from "./views/admin/Index";
import AdminUser from "./views/admin/User";
import AdminUserCreate from "./views/admin/UserCreate";
import AdminUserUpdate from "./views/admin/UserUpdate";
import NotFound from "./views/NotFound";
import AdminValidations from "./views/admin/ValidationRules";
import ValidationUpdate from "./views/admin/ValidationUpdate";
import { useIsAuthenticated } from "@azure/msal-react";

function AppRoutes() {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/terminal/:termId" element={<TerminalInformation />} />
          <Route path="/preLoad/:termId" element={<PreLoad />} />
          <Route path="/ebol/:termId" element={<Bol />} />
          <Route path="/admin" element={<AdminIndex />} />
          <Route path="/admin/user" element={<AdminUser />} />
          <Route path="/admin/user/create" element={<AdminUserCreate />} />
          <Route
            path="/admin/user/update/:userId"
            element={<AdminUserUpdate />}
          />
          <Route path="/admin/validationRules" element={<AdminValidations />} />
          <Route
            path="/admin/validationRules/update/:validationId"
            element={<ValidationUpdate />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Loggin />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
