import { useState, useEffect } from "react";
import CardContainer from "../blocks/card_container";
import Toolbar from "../blocks/toolbar";
import PageHeader from "../blocks/page_header";
import Loader from "../elements/loader";
import { apiClient } from "../lib/axios";


function Index() {
  var [stateTerminals, setTerminals] = useState();
  var [stateMainTerminals, setMainTerminals] = useState();
  var [stateOnLoading, setLoading] = useState(true);
  
  async function getTerminals() {
    try {
      const fetchTerminals = await apiClient.get('Web/GetTerminalsDisruption', {
        headers: {
          "Access-Control-Allow-Origin": "*"
      }}).then((response)=>{
        setTerminals(response.data)
        setMainTerminals(response.data)
        setLoading(false)
      })
            
    } catch (error) {
      console.log(error)
    }
  }

  function TerminalSearch(terminals){
    var search_terminals = []
    if(terminals != "") {
      debugger
      search_terminals = stateMainTerminals.filter(terminal => terminal.name.replace(/\s/g, "").toLowerCase().includes(terminals.replace(/\s/g, "").toLowerCase()));
      setTerminals(search_terminals)
    } else {
      setTerminals(stateMainTerminals);
    }
    return search_terminals;
  }

  useEffect(() => { 
    getTerminals() 
  }, [])
  
  return (
    <>
      <PageHeader logged={true}/>
      <Toolbar callback={TerminalSearch} />
      {stateOnLoading ? <Loader /> : <CardContainer terminals={stateTerminals}/>}
    </>
  )
}
 
export default Index;