import { loginRequest } from "../config/authConfig";
import { loginRequest as internalLogin } from '../config/InternalAuthConfig';
import { useMsal} from '@azure/msal-react';

const internalUrls = ['localhost:3000/', 'terminalfastpass.xom.cloud', 'terminalfastpassdev.xom.cloud'];
export const isInternalAccess = internalUrls.some(url => window.location.href.includes(url))

export async function authInstance() {
    const { instance, accounts } = useMsal();

    const authInstanceData = await instance.acquireTokenSilent({
        ...(isInternalAccess ? internalLogin : loginRequest),
        account: accounts[0]
    })
    localStorage.setItem('id_token', isInternalAccess ? authInstanceData.accessToken : authInstanceData.idToken) 

    if(authInstanceData.idTokenClaims.preferred_username) {
        localStorage.setItem('preferred_username', authInstanceData.idTokenClaims.preferred_username)
    }
    return authInstanceData
}