import { useState, useEffect } from "react";
import { loginRequest } from "../../config/authConfig";
import { useMsal } from "@azure/msal-react";
import { PlusCircleFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { userForm, userTMS6Form, userExternalForm } from "../../forms/userForm";
import Form from "../../blocks/form";
import Loader from "../../elements/loader";
import UserModel from "../../models/UserModel";
import TerminalHeader from "../../elements/terminal_header";
import { toast, ToastContainer } from "react-toastify";
import { apiClient } from "../../lib/axios";

function AdminUserCreate() {
  var [stateOnLoading, setLoading] = useState(true);
  var [stateUsers, setUsers] = useState();
  var [stateExternalUser, setExternalUser] = useState(false);
  var [stateExternalID, setExternalID] = useState(" ");
  var [stateInternalID, setInternalID] = useState(" ");
  var [stateFirstName, setFirstName] = useState();
  var [stateLastName, setLastName] = useState();
  var [stateCompanyName, setCompanyName] = useState();
  var [statePhoneNumber, setPhoneNumber] = useState();
  var [statePhoneCountryCode, setPhoneCountryCode] = useState();
  var [stateEmail, setEmail] = useState();
  var [stateRole, setRole] = useState();
  var [stateUserTerminals, setUserTerminals] = useState();
  var [stateTerminals, setTerminals] = useState();

  function setFirstNameValues(evt) {
    setFirstName(evt.target.value);
  }

  function setLastNameValues(evt) {
    setLastName(evt.target.value);
  }

  function setCompanyNameValues(evt) {
    setCompanyName(evt.target.value);
  }

  function setPhoneNumberValues(evt) {
    setPhoneNumber(evt.target.value);
  }

  function setPhoneCountryCodeValues(evt) {
    setPhoneCountryCode(evt.target.value);
  }

  function setEmailValues(evt) {
    setEmail(evt.target.value);
  }

  function setRoleValues(evt) {
    setRole(evt.label.toString());
  }

  function setUserTerminalsValues(evt) {
    setUserTerminals(evt);
  }

  function setInternalIdValues(evt) {
    setInternalID(evt.target.value);
  }

  function setInternalIdValue(value) {
    setInternalID(value);
  }

  function setExternalIdValues(evt) {
    setExternalID(evt.target.value);
  }

  function submitForm() {
    toast("Creating user...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    try {
      let userModel = new UserModel();
      userModel.firstName = stateFirstName;
      userModel.lastName = stateLastName;
      userModel.email = stateEmail;
      userModel.role = stateRole;
      userModel.internalCode = stateInternalID;
      userModel.externalCode = stateExternalID;
      userModel.acceptedToSVersion = "";
      userModel.acceptedDataPrivacyVersion = "";
      userModel.profile = "";

      
        var user = userModel;
        apiClient
          .post("user/new", user)
          .then(
            (response) => {
              toast.success("New user created!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setTimeout(() =>{
                window.location.reload();
              }, 2500);
            },
            (error) => {
              let msg = "Error to create new user. Try again later.";
              if(error.response.data.includes("exists")){
                msg = error.response.data;
              }
              toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          );
    } catch {
      toast.error("Please, fill all the fields before submit.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  const getTerminals = () => {
    try{
        apiClient
          .get("Terminal/List", {
            headers: {
              "Access-Control-Allow-Origin": "*"
            },
          })
          .then((response) => {
            setTerminals(
              response.data.map((x) => ({ label: x.name, value: x.termId }))
            );
          });
        return true;
      }
      catch(e) {
        return false;
      };
  };

  useEffect(() => {
    getTerminals();
  }, []);

  return (
    <>
      <>
        <div className="container container-space">
          <div className="row alignment">
            <Form
              fields={userTMS6Form(
                stateInternalID,
                setInternalIdValues,
                stateExternalID,
                setExternalIdValues
              )}
            />
            <Form
              fields={userForm(
                stateFirstName,
                setFirstNameValues,
                stateLastName,
                setLastNameValues,
                stateEmail,
                setEmailValues,
                stateRole,
                setRoleValues,
                stateUserTerminals,
                stateTerminals,
                setUserTerminalsValues
              )}
            />
            <button
              onClick={submitForm}
              className="em-c-btn em-c-btn--primary btn--right em-u-margin-top-double create-user-margin"
              id="index-button"
            >
              <span className="em-c-btn__text ">Create</span>
            </button>
          </div>
          <ToastContainer />
        </div>
      </>
    </>
  );
}

export default AdminUserCreate;
