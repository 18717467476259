import TerminalHeader from '../elements/terminal_header';
import SidebarMenu from '../elements/sidebar'
import {useParams, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { loginRequest } from "../config/authConfig";
import { useMsal} from '@azure/msal-react';
import Loader from '../elements/loader';
import Collapsible from '../elements/collapsible';
import Table from '../elements/table';
import Modal from '../elements/modal';
import { InfoCircleFill, ClockHistory } from 'react-bootstrap-icons';
import BarChartDate from '../blocks/chart_date';
import WaitTimeChartModel from '../models/waitTimeModel';
import WaitTimeForm from '../forms/waitTimeForm';
import Form from '../blocks/form';
import moment from 'moment'
import API from "../services/api";
import { apiClient } from '../lib/axios';

function convertDateTime(dt) {
  return moment.parseZone(dt).utc(true).format("YYYY/MM/DD hh:mm A")
}

function TerminalInformation() {
  let navigate = useNavigate();

  var [stateTerminal, setTerminal] = useState({name: ""});
  var [stateWaitTime, setWaitTime] = useState(WaitTimeChartModel);
  var [stateDisruptions, setDisruptions] = useState({
    "disruption": [
        {
            "type": "Disruption",
            "start_dateUTC": "",
            "end_dateUTC": "",
            "backup_terminal": "",
            "product": [{}]
        }
    ]
  });
  var [stateOnLoading, setLoading] = useState(true);
  var [stateWaitTimeOnLoading, setWaitTimeOnLoading] = useState(false);

  var [openModal, setOpenModal] = useState(false);

  let {termId} = useParams();
  let tableHeader = ["Product Description", "Disruption"];
  let tableValues = ["description", "status"];
  
  const { instance, accounts } = useMsal();

  const getTerminalInformation = () => {

      setLoading(true);
    try{
      apiClient.get('Terminal/Get?termId=' + termId).then((response) => {
        if(response.status != 200) {
          return navigate("/error");
        }
        setTerminal(response.data);
        setLoading(false);
      }, (error) => {
        return navigate("/error");
      });

      apiClient.get('Web/GetTerminalsDisruptionByTermID?termID=' + termId).then((response) => {
        response.data.disruption.map(dt => dt.product.map(prod => prod["status"]="Unavailable"));
        setDisruptions(response.data);
      });
      window.scrollTo({ top, behavior: "smooth" });
      return true
    }
    catch(e) {
      return false
    };
  }

  const getWaitTime = (evt) => {
    setWaitTime({labels: [], data: []})
    setWaitTimeOnLoading(true)
 

    let start = ''.concat(evt.$d.getFullYear(),'-',(evt.$d.getMonth() + 1).toString().padStart(2, "0"),'-',evt.$d.getDate().toString().padStart(2, "0") ,'T','00:00:00.000Z');
    let end = ''.concat(evt.$d.getFullYear(),'-',(evt.$d.getMonth() + 1).toString().padStart(2, "0"),'-',evt.$d.getDate().toString().padStart(2, "0") ,'T','23:59:00.000Z');

      apiClient.get('Web/GetWaitTimeByPlantCode?termID=' + termId + '&startTime=' + start + '&endTime=' + end).then((response) => {
        let stt = stateWaitTime;
        stt.labels = response.data.map(dt => dt.measured_time_utc);
        stt.values = response.data.map(dt => dt.wait_time);
        setWaitTime(stt);
        setWaitTimeOnLoading(false)
      });
  }

  useEffect(() => { getTerminalInformation(); }, [termId])

  function IsCurrentDisruption(dt){
    var currentDisruption = []
    if(dt !== undefined) {
      var date_now = moment(new Date()).format("YYYY/MM/DD kk:mm");
      var end_date = moment(dt["end_dateUTC"]).format("YYYY/MM/DD kk:mm");
      var start_date = moment(dt["start_dateUTC"]).format("YYYY/MM/DD kk:mm");
      if (start_date < date_now
          && end_date >= date_now) {
          currentDisruption.push(dt)
      }
        return {response: currentDisruption.length > 0, value: currentDisruption}
    }
    return {response: currentDisruption > 0, value: currentDisruption}
}

function IsPlannedDisruption(dt){
  var currentDisruption = []
  var haveDisruption = false
  if(dt !== undefined) {
    var date_now = moment(new Date()).format("YYYY/MM/DD kk:mm");
    var start_date = moment(dt["start_dateUTC"]).format("YYYY/MM/DD kk:mm");
    if (start_date >= date_now) {
        haveDisruption = true
        currentDisruption.push(dt)
    }
      return {response: currentDisruption.length > 0, value: currentDisruption}
  }
  return {response: currentDisruption > 0, value: currentDisruption}
}

  return (
    <>
    {
      stateOnLoading ? <Loader /> :
      <> 
        <TerminalHeader name={stateTerminal.name} address={stateTerminal.addr1}/>
        <div className="container">
        <div className="em-l em-l--two-column-sticky ">
          <div className="em-l__secondary"><div className="fpo-block"><SidebarMenu termId={termId} /></div></div>
          <div className="em-l__main">
            <div className="fpo-block fpo-block--dark">
              <div className="container">
                <div className="row">
                <Modal isOpen={openModal} functionality={() => setOpenModal(false)}
                    content={<>
                      <Form fields={WaitTimeForm(getWaitTime)} />
                      {
                        stateWaitTimeOnLoading ?
                        <Loader/>
                        :
                        <BarChartDate
                          labels={stateWaitTime.labels} 
                          values={stateWaitTime.values}
                        />
                      }                                    
                    </>} 
                    width="80vw"  
                    title={<>
                      <ClockHistory />
                        History Time
                      </>} 
                      description="Select the date to check the waiting time history on the day" 
                  />
                  <Collapsible classTypo={"collapsible--blue collapsible--main"} 
                    append={[<span onClick={() => setOpenModal(true)} style={{cursor: 'pointer'}}>
                      Waiting Time: {stateDisruptions.waiting_time} <InfoCircleFill/>
                    </span>]}
                    title="Real Time Status: Terminal Operating" 
                    content={
                      stateDisruptions.disruption.length > 0 ?
                      stateDisruptions.disruption.map(disruption => {
                        return (
                          IsCurrentDisruption(disruption).response ? 
                          <Collapsible 
                            title={disruption.type + " from " + moment.parseZone(disruption.start_dateUTC).utc(true).format("YYYY/MM/DD hh:mm A") + " to " 
                              + moment.parseZone(disruption.end_dateUTC).utc(true).format("YYYY/MM/DD hh:mm A")} 
                            content={[
                              <Table
                                header={tableHeader} 
                                content={disruption.product} 
                                values={tableValues}/>
                            ]}
                          />
                          :
                          <></>
                        )
                      })
                      :
                      ["There is no planned disruptions"]
                    } />
                  {stateDisruptions !== undefined ? 
                    <Collapsible classTypo={"collapsible--blue collapsible--main"} title="Planned Disruptions " content={
                      stateDisruptions.disruption.length > 0 ?
                        stateDisruptions.disruption.map(disruption => {
                          return (
                            IsPlannedDisruption(disruption).response ?
                            <Collapsible 
                              title={disruption.type + " from " + convertDateTime(disruption.start_dateUTC) + " to " + convertDateTime(disruption.end_dateUTC)} 
                              content={[
                                <Table
                                  header={tableHeader} 
                                  content={disruption.product} 
                                  values={tableValues}/>
                              ]}
                            />
                            :
                            <></>
                          )
                        }) 
                        :
                        ["There is no planned disruptions"]
                    }/>
                  :
                  <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </>
    }
    
    </>
  );
}
 
export default TerminalInformation;