import { CaretRightFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const SidebarMenu = ({ termId }) => {
  let navbar = [
    {
      label: "Terminal Status",
      path: "/terminal/" + termId,
    },
    {
      label: "Pre Load",
      path: "/preLoad/" + termId,
    },
    {
      label: "eBOL",
      path: "/ebol/" + termId,
    },
  ];

  const restrictedTermIds = ["0000145", "0000242", "0000144", "0000231", "0000274"];


  return (
    <>
      <div className="em-c-header-overlay">
        <header className="em-c-header em-c-header--vertical " role="banner">
          <div className="em-c-header__inner">
            <div className="em-c-header__blue ">
              <div className="em-c-header__body">
                <div className="em-c-header__title-container ">
                  <h2 className="em-c-header__title">
                    <Link to="/" className="em-c-header__title-logo">
                      Back to Terminal List
                    </Link>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <nav className="em-c-tree " aria-label="Tree navigation">
            <ul
              className="em-c-tree__list em-c-tree__list--flush"
              role="menubar"
              aria-orientation="vertical"
            >
              <ul>
            {navbar !== undefined ? (
              navbar.map((nav) => {
                if (restrictedTermIds.includes(termId) && nav.label !== "eBOL") {
                  return null;
                }
                return (
                  <li className="em-c-tree__item" role="menuitem" key={nav.path}>
                    <Link
                      to={nav.path}
                      className="em-c-tree__link em-c-tree__link--has-children em-js-tree-dropdown-trigger"
                    >
                      <span className="em-c-tree__text">{nav.label}</span>
                      <CaretRightFill className="em-c-icon em-c-icon--small em-c-tree__icon"></CaretRightFill>
                    </Link>
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>
            </ul>
          </nav>
        </header>
        <div
          className="em-u-font-size-small-2 em-u-font-style-light"
          id="text-end"
        >
          <p className="sidebar-description">
            *Density can vary by +/- 2%. Imperial is not responsible for the
            accuracy of the information on this page, and cannot be held for
            claims or losses.
          </p>
        </div>
      </div>
    </>
  );
};

export default SidebarMenu;
