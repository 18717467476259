import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import validationForm from "../../forms/validationForm";
import Form from "../../blocks/form";
import Loader from "../../elements/loader";
import ValidationModel from "../../models/ValidationsModel";
import TerminalHeader from "../../elements/terminal_header";
import { toast, ToastContainer } from "react-toastify";
import { apiClient } from "../../lib/axios";


function ValidationUpdate() {
  let { validationId } = useParams();

  let [stateOnLoading, setLoading] = useState(true);
  let [stateTableName, setTableName] = useState();
  let [stateField, setField] = useState();
  let [stateOperation, setOperation] = useState();
  let [stateValue, setValue] = useState();
  let [stateErrorMessage, setErrorMessage] = useState();
  let [stateResolutionMessage, setResolutionMessage] = useState();

  function setTableNameValue(evt) {
    setTableName(evt.target.value);
  }

  function setFieldValue(evt) {
    setField(evt.target.value);
  }

  function setOperationValue(evt) {
    setField(evt.target.value);
  }

  function setValueValue(evt) {
    setValue(evt.target.value);
  }

  function setErrorMessageValue(evt) {
    setErrorMessage(evt.target.value);
  }

  function setResolutionMessageValue(evt) {
    setResolutionMessage(evt.target.value);
  }

  async function submitForm() {
    toast("Updating validation...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    let validationModel = new ValidationModel();
    validationModel.id = validationId;
    validationModel.errorMessage = stateErrorMessage;
    validationModel.resolutionMessage = stateResolutionMessage;

    var form = validationModel;
    try {
      await apiClient.put("Validation/UpdateValidation", form)
      toast.success("Success to update the validation rule.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    } catch (error) {
      toast.error("Error to edit the validation rule. Try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
  }

  async function getValidations() {
    try {
      const fetchValidationById = await apiClient.get("Validation/ValidationById?validationId=" + validationId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        }
      })
      setLoading(false);
      setTableName(fetchValidationById.data.tableName)
      setField(fetchValidationById.data.field)
      setOperation(fetchValidationById.data.operation)
      setValue(fetchValidationById.data.value)
      setErrorMessage(fetchValidationById.data.errorMessage)
      setResolutionMessage(fetchValidationById.data.resolutionMessage)
      window.scrollTo({ top, behavior: "smooth" });
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => { 
    getValidations() 
  }, [])

  return (
    <>
      {stateOnLoading ? <Loader /> :
      <div className="container container-space">
        <div className="row">
         <TerminalHeader name={"Update Validation Rule"}/>
         <hr />
        </div>
        <div className="row alignment">
          <Form fields={validationForm(stateTableName, setTableNameValue, stateField, setFieldValue, stateOperation, setOperationValue, stateValue, setValueValue, stateErrorMessage, setErrorMessageValue, stateResolutionMessage, setResolutionMessageValue)} />
          <button onClick={submitForm} className="em-c-btn em-c-btn--primary btn--right em-u-margin-top-double" id="index-button">
            <span className="em-c-btn__text">Update</span>
          </button>

          <Link to="/admin/validationRules">
            <button className="em-c-btn em-c-btn--secondary btn--right em-u-margin-top-double" id="index-button">
            <span className="em-c-btn__text">Go back</span>
            </button>
          </Link>
        </div>
        <ToastContainer/>
      </div>
      }
    </>
  );
}

export default ValidationUpdate;
