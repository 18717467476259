import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";
import { msalConfig as internalAuthConfig } from "../config/InternalAuthConfig";
import { isInternalAccess } from "../utils/auth";

const msalInstance = new PublicClientApplication(isInternalAccess ? internalAuthConfig : msalConfig);

const AuthProvider = ({children}) => {
    return (
        <MsalProvider instance={msalInstance}>
            {children}
        </MsalProvider>
        
    )
}

export default AuthProvider