import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  useMsal,
} from '@azure/msal-react';
import Feedback from "../elements/feedback";
import { apiClient } from "../lib/axios";

function Header({ isAdmin }) {

  let isDev = process.env.REACT_APP_IS_DEV == "true" ? true : false;
  
  const { accounts } = useMsal();
  var [internalAccounts, setInternalAccounts] = useState();
  var [internalAccount, setInternalAccount] = useState();
  var [showAccounts, setShowAccounts] = useState(false);

  async function getInternalAccounts(){
    try {
      const loggedAccount = document.cookie.split('; ').find((row) => row.startsWith('internalAccount='))?.split('=')[1];
      if(internalAccount) setInternalAccount(loggedAccount)
      const fetchInternalAccounts = await apiClient.get('user/internalAccounts', {headers: {"Access-Control-Allow-Origin": "*",}})
      setInternalAccounts(fetchInternalAccounts.data)

      if(!loggedAccount) {
        setInternalAccount(fetchInternalAccounts.data[0])
        document.cookie = `internalAccount=${response.data[0]}; expires=Session; path=/`
      }
    } catch (error) {
      console.log(error)
    }
  }

  function updateInternalAccount(account) {
    setShowAccounts(false);
    if(account != internalAccount) {
      document.cookie = `internalAccount=${account}; expires=Session; path=/`;
      setInternalAccount(account);
      location.reload();
    }
  }

  useEffect(() => { 
    getInternalAccounts() 
  }, [])

  function showInternalAccount() {
    if(internalAccount != undefined 
      && internalAccount != null 
      && internalAccount != "") {
      return ` - ${internalAccount} ▼`;
    }
    return "";
  }

  return (
    <header
      className={"em-c-header em-c-header--condensed em-c-header--blue"}
      style={{ padding: '5px'}}
      role="banner"
    >
        <div className="layout-header" id="header-block" >
          <div className="em-c-header__body header-body">
            <div className="em-c-header__title-container ">
              <h2 className="em-c-header__title">
                <Link to="/" rel="home" className="em-c-header__title-link">{isDev ? "Terminal FastPass Development" : "Terminal FastPass" }</Link>
              </h2>
            </div>
            <nav id="nav" aria-label="Main navigation" className="header-nav">
              <ul className="em-c-primary-nav__list" role="menubar" id="horizontalMenu">
                <li className="em-c-primary-nav__item " role="menuitem" key="menu-item">
                  <Link to="/" className="em-c-primary-nav__link  em-is-current">Home</Link>
                </li>
                <li className="em-c-primary-nav__item " role="menuitem" key="menu-item">
                  <Feedback />
                </li>
                  {
                    isAdmin &&
                      <li className="em-c-primary-nav__item " role="menuitem" key="menu-item-2">
                        <Link to="/admin" rel="admin" className="em-c-primary-nav__link  em-is-current">Admin</Link>
                      </li>
                  }
                      <li className="em-c-primary-nav__item " role="menuitem" key="menu-item-3">
                      <span
                        className="em-c-primary-nav__link  em-is-current em-u-font-style-light headerBtn"
                        href="#"
                        aria-expanded="false"
                        aria-current="false"
                        aria-label="Home"
                        onClick={()=> {setShowAccounts(!showAccounts)}}
                      >
                        {accounts[0]?.name + showInternalAccount()}
                      </span>
                      <div>
                        { showAccounts && 
                          <div className="headerDropDownHide">
                            <div className="headerDropDown"> 
                              {internalAccounts?.map((ia) => (
                                <div 
                                  className={internalAccount == ia ? 'headerDropDownItemActive' : 'headerDropDownItem'} 
                                  onClick={() => updateInternalAccount(ia)} key={ia}>{ia}
                                </div>
                              ))}
                            </div>
                          </div>}
                      </div>
                    </li>
              </ul>
            </nav>
          </div>
        </div>
    </header>    
  )};
  
export default Header;