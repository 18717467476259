import Header from '../blocks/header'
import Footer from '../blocks/footer'

import { loginRequest } from "../config/authConfig";
import { loginRequest as internalLogin } from '../config/InternalAuthConfig';
import {
  useMsal,
} from '@azure/msal-react';
import PageHeader from "../blocks/page_header";
import { useState, useEffect } from "react";
import { authInstance, isInternalAccess } from '../utils/auth';

function Loggin(){
  
  const { instance, accounts  } = useMsal();

  var [stateLoggedIn, setLoggedIn] = useState(false);

  async function handleLogin(loginType){

    const authInstanceData = await instance.acquireTokenSilent({
        ...(isInternalAccess ? internalLogin : loginRequest),
        account: accounts[0]
    }).catch(e => {
      console.log(e);
      instance.loginRedirect(isInternalAccess ? internalLogin : loginRequest)
    });
    localStorage.setItem('id_token', isInternalAccess ? authInstanceData.accessToken : authInstanceData.idToken) 

    if(authInstanceData.idTokenClaims.preferred_username) {
        localStorage.setItem('preferred_username', authInstanceData.idTokenClaims.preferred_username)
    }
  }

  useEffect(() => { 
    async function getToken() {
      await handleLogin();
    }
    getToken();
   }, [])

  return (
    <>
      <div className="logged-out">
        <Header handleLogin={() => handleLogin("redirect")} />
        <PageHeader logged={false}/>
        <Footer />
      </ div>
    </>
  )
};

export default Loggin;