import { useEffect } from "react";
import moment from 'moment'
import { CardNew } from "../components/card";

const CardContainer = ({terminals}) => {
    useEffect(() => {
      }, [terminals])
    function haveCurrentDisruption(dt){
        var currentDisruption = []
        if(dt !== undefined) {
            dt.forEach(d => {
                var date_now = moment(new Date()).format("YYYY/MM/DD kk:mm");
                var end_date = moment(d["end_dateUTC"]).format("YYYY/MM/DD kk:mm");
                var start_date = moment(d["start_dateUTC"]).format("YYYY/MM/DD kk:mm");
                if (start_date < date_now
                    && end_date > date_now) {
                    currentDisruption.push(d)
                }
            });
            return {response: currentDisruption.length > 0, value: currentDisruption}
        }
        return {response: currentDisruption > 0, value: currentDisruption}
    }

    function havePlannedDisruption(dt){
        var haveDisruption = false
        if(dt !== undefined) {
            dt.forEach(d => {
                var date_now = moment(new Date()).format("YYYY/MM/DD kk:mm");
                var start_date = moment(d["start_dateUTC"]).format("YYYY/MM/DD kk:mm");
                if (start_date > date_now) {
                    haveDisruption = true
                }
            });
            return haveDisruption
        }
        return haveDisruption
    }

    function convertDateTime(dt) {
        return moment.parseZone(dt).utc(true).format("YYYY/MM/DD hh:mm A")
    }

    return(
        <>
        <div className="container">
            <div className="row">
                <div className="em-l-grid em-l-grid--1-to-3up ">
                {
                    (terminals !== undefined && typeof terminals !== 'string') ?
                    terminals.map(card =>{
                        const currentDisruption = haveCurrentDisruption(card.disruption)
                        const disruptionType = currentDisruption.value.length > 0 ? "Downtime" : havePlannedDisruption(card.disruption) ? "Terminal Operating with planned disruption" : "Terminal Operating"
                        const disruptionStartDateTime = currentDisruption.value.length > 0 ? convertDateTime(currentDisruption.value[0].start_dateUTC) : ''
                        const disruptionEndDateTime = currentDisruption.value.length > 0 ? convertDateTime(currentDisruption.value[0].end_dateUTC) : ''
                        return (
                            <div className="em-l-grid__item" key={card.name}>
                                <div className="fpo-block">
                                    <CardNew 
                                        name={card.name} 
                                        address={card.address} 
                                        disruptionType={disruptionType} 
                                        disruptionStartDateTime={disruptionStartDateTime}
                                        disruptionEndDateTime={disruptionEndDateTime}
                                        terminal_id={card.term_id}
                                    />
                                </div>
                            </div>
                        )
                    }) : (<></>)
                }
                </div>
            </div>
            
        </div>
        </>
    );
};

export default CardContainer;