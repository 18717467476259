export function userForm(
  firstNameValues,
  changeFirstName,
  lastNameValues,
  changeLastName,
  emailValues,
  changeEmail,
  roleValues,
  changeRole
) {
  const userForm = [
    {
      type: "text",
      name: "First-name",
      label: "First Name",
      placeholder: "First Name",
      id: "First-name",
      value: firstNameValues,
      onChangeFunc: changeFirstName,
      enable: true,
    },
    {
      type: "text",
      name: "Last-name",
      label: "Last Name",
      placeholder: "Last Name",
      id: "Last-name",
      value: lastNameValues,
      onChangeFunc: changeLastName,
      enable: true,
    },
    {
      type: "email",
      name: "email",
      label: "Email",
      placeholder: "Email",
      id: "email",
      value: emailValues,
      onChangeFunc: changeEmail,
      enable: true,
    },
    {
      type: "select",
      name: "Role",
      label: "Role",
      placeholder: "Role",
      id: "Role",
      value: [
        { label: "Admin", value: "admin" },
        { label: "Driver", value: "driver" },
        { label: "Carrier", value: "carrier" },
        { label: "Customer", value: "customer" },
        { label: "Support", value: "support" },
      ],
      selectedValue: roleValues,
      onChangeFunc: changeRole,
      enable: true,
    },
  ];
  return userForm;
}

export function userTMS6Form(
  internalIDValue,
  changeInternalID,
  externalIDValue,
  changeExternalID
) {
  const userTMS6Form = [
    {
      type: "text",
      name: "External-ID",
      label: "Okta ID",
      placeholder: "Okta ID",
      id: "External-ID",
      value: externalIDValue,
      onChangeFunc: changeExternalID,
      enable: true,
    },
    {
      type: "text",
      name: "Internal-ID",
      label: "Internal ID",
      placeholder: "Internal ID",
      id: "Internal-ID",
      value: internalIDValue,
      onChangeFunc: changeInternalID,
      enable: true,
    },
  ];
  return userTMS6Form;
}

export function userExternalForm(changeExternalUser) {
  const userExternalForm = [
    {
      type: "select",
      name: "External-user-field",
      label: "Is user from TMS6?",
      placeholder: "Is user from TMS6?",
      id: "external-user-field",
      value: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      onChangeFunc: changeExternalUser,
    },
  ];
  return userExternalForm;
}
