import axios from "axios"
import { isInternalAccess } from "../utils/auth";

const preferredUsername = localStorage.getItem('preferred_username');

const headers = {
    "Internal_id": document.cookie.split('; ').find((row) => row.startsWith('internalAccount='))?.split('=')[1],
    "Authorization": "Bearer " + (localStorage.getItem('id_token') ?? ""),
}

headers['External_id'] = preferredUsername

export const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
    headers
})